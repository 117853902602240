@-webkit-keyframes BrandLogoLink_rotate__9ggyt {
  4.5% {
    transform: rotate(32.7deg);
  }
  9% {
    transform: rotate(32.7deg);
  }

  13.5% {
    transform: rotate(65.4deg);
  }
  18% {
    transform: rotate(65.4deg);
  }

  22.5% {
    transform: rotate(98.1deg);
  }
  27% {
    transform: rotate(98.1deg);
  }

  31.5% {
    transform: rotate(130.8deg);
  }
  36% {
    transform: rotate(130.8deg);
  }

  40.5% {
    transform: rotate(163.5deg);
  }
  45% {
    transform: rotate(163.5deg);
  }

  49.5% {
    transform: rotate(196.2deg);
  }

  54% {
    transform: rotate(196.2deg);
  }

  58.5% {
    transform: rotate(228.9deg);
  }

  63% {
    transform: rotate(228.9deg);
  }

  67.5% {
    transform: rotate(261.6deg);
  }

  72% {
    transform: rotate(261.6deg);
  }

  76.5% {
    transform: rotate(294.3deg);
  }

  81% {
    transform: rotate(294.3deg);
  }

  85.5% {
    transform: rotate(327deg);
  }

  90% {
    transform: rotate(327deg);
  }

  95% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes BrandLogoLink_rotate__9ggyt {
  4.5% {
    transform: rotate(32.7deg);
  }
  9% {
    transform: rotate(32.7deg);
  }

  13.5% {
    transform: rotate(65.4deg);
  }
  18% {
    transform: rotate(65.4deg);
  }

  22.5% {
    transform: rotate(98.1deg);
  }
  27% {
    transform: rotate(98.1deg);
  }

  31.5% {
    transform: rotate(130.8deg);
  }
  36% {
    transform: rotate(130.8deg);
  }

  40.5% {
    transform: rotate(163.5deg);
  }
  45% {
    transform: rotate(163.5deg);
  }

  49.5% {
    transform: rotate(196.2deg);
  }

  54% {
    transform: rotate(196.2deg);
  }

  58.5% {
    transform: rotate(228.9deg);
  }

  63% {
    transform: rotate(228.9deg);
  }

  67.5% {
    transform: rotate(261.6deg);
  }

  72% {
    transform: rotate(261.6deg);
  }

  76.5% {
    transform: rotate(294.3deg);
  }

  81% {
    transform: rotate(294.3deg);
  }

  85.5% {
    transform: rotate(327deg);
  }

  90% {
    transform: rotate(327deg);
  }

  95% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.BrandLogoLink_film__qPiW6 {
  position: absolute;
  top: -28.7%;
  left: 50%;
  margin-left: -36%;
  width: 72%;
}

.BrandLogoLink_link__yx2pR:hover .BrandLogoLink_film__qPiW6,

.BrandLogoLink_link__yx2pR.focus-visible .BrandLogoLink_film__qPiW6 {
  -webkit-animation: BrandLogoLink_rotate__9ggyt 15s linear infinite;
          animation: BrandLogoLink_rotate__9ggyt 15s linear infinite;
}

.BrandLogoLink_viewmaster__8iXY2 {
}

.BlogPostImage_checkeredBackground__ZPW_9 {
  background-color: transparent;
  background-image: linear-gradient(45deg, #e5e7eb 25%, transparent 25%),
    linear-gradient(135deg, #e5e7eb 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e5e7eb 75%),
    linear-gradient(135deg, transparent 75%, #e5e7eb 75%);
  background-size: calc(2 * 20px) calc(2 * 20px);
  background-position: 0 0, 20px 0, 20px calc(-1 * 20px), 0 calc(-1 * 20px);
}

@-webkit-keyframes Loader_rotate__RDGIR {
  4.5% {
    transform: rotate(32.7deg);
  }
  9% {
    transform: rotate(32.7deg);
  }

  13.5% {
    transform: rotate(65.4deg);
  }
  18% {
    transform: rotate(65.4deg);
  }

  22.5% {
    transform: rotate(98.1deg);
  }
  27% {
    transform: rotate(98.1deg);
  }

  31.5% {
    transform: rotate(130.8deg);
  }
  36% {
    transform: rotate(130.8deg);
  }

  40.5% {
    transform: rotate(163.5deg);
  }
  45% {
    transform: rotate(163.5deg);
  }

  49.5% {
    transform: rotate(196.2deg);
  }

  54% {
    transform: rotate(196.2deg);
  }

  58.5% {
    transform: rotate(228.9deg);
  }

  63% {
    transform: rotate(228.9deg);
  }

  67.5% {
    transform: rotate(261.6deg);
  }

  72% {
    transform: rotate(261.6deg);
  }

  76.5% {
    transform: rotate(294.3deg);
  }

  81% {
    transform: rotate(294.3deg);
  }

  85.5% {
    transform: rotate(327deg);
  }

  90% {
    transform: rotate(327deg);
  }

  95% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes Loader_rotate__RDGIR {
  4.5% {
    transform: rotate(32.7deg);
  }
  9% {
    transform: rotate(32.7deg);
  }

  13.5% {
    transform: rotate(65.4deg);
  }
  18% {
    transform: rotate(65.4deg);
  }

  22.5% {
    transform: rotate(98.1deg);
  }
  27% {
    transform: rotate(98.1deg);
  }

  31.5% {
    transform: rotate(130.8deg);
  }
  36% {
    transform: rotate(130.8deg);
  }

  40.5% {
    transform: rotate(163.5deg);
  }
  45% {
    transform: rotate(163.5deg);
  }

  49.5% {
    transform: rotate(196.2deg);
  }

  54% {
    transform: rotate(196.2deg);
  }

  58.5% {
    transform: rotate(228.9deg);
  }

  63% {
    transform: rotate(228.9deg);
  }

  67.5% {
    transform: rotate(261.6deg);
  }

  72% {
    transform: rotate(261.6deg);
  }

  76.5% {
    transform: rotate(294.3deg);
  }

  81% {
    transform: rotate(294.3deg);
  }

  85.5% {
    transform: rotate(327deg);
  }

  90% {
    transform: rotate(327deg);
  }

  95% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Loader_film__Yk9nP {
  position: absolute;
  top: -28.7%;
  left: 50%;
  margin-left: -36%;
  width: 72%;
  -webkit-animation: Loader_rotate__RDGIR 15s linear infinite;
          animation: Loader_rotate__RDGIR 15s linear infinite;
}

.Loader_viewmaster__Irlok {
}

.CheckboxField_input__5t4Aq:checked ~ .CheckboxField_checkbox__H5M3G {

    --tw-text-opacity: 1;

    color: rgba(80, 81, 79, var(--tw-text-opacity))
}

.CheckboxField_input__5t4Aq:active ~ .CheckboxField_checkbox__H5M3G, .CheckboxField_input__5t4Aq.focus-visible ~ .CheckboxField_checkbox__H5M3G, .CheckboxField_input__5t4Aq.CheckboxField_focus-visible__80_VC ~ .CheckboxField_checkbox__H5M3G {

    --tw-border-opacity: 1;

    border-color: rgba(241, 91, 181, var(--tw-border-opacity))
}

.RadioField_input__6TgZc:checked ~ .RadioField_radio__OVFM4 {

    --tw-bg-opacity: 1;

    background-color: rgba(241, 91, 181, var(--tw-bg-opacity))
}

.RadioField_input__6TgZc:active ~ .RadioField_radio__OVFM4, .RadioField_input__6TgZc:focus ~ .RadioField_radio__OVFM4 {

    --tw-border-opacity: 1;

    border-color: rgba(241, 91, 181, var(--tw-border-opacity))
}

.Dialog_outer__HAg_n {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  top: auto;
}

.Dialog_inner__MOcLx {
  max-height: 90vh;
  min-height: 40vh;
}

@media (min-width: 640px) {
  .Dialog_outer__HAg_n {
    max-width: 640px;
  }
  .Dialog_outer__HAg_n {
    right: auto;
  }
  .Dialog_outer__HAg_n {
    bottom: auto;
  }
  .Dialog_outer__HAg_n {
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  .Dialog_inner__MOcLx {
    min-height: auto;
  }
}

.NewListCta_scrollContainer__ZYn3D {
  border-right: 24px solid rgba(0, 0, 0, 0);
}

@media (min-width: 640px) {
  .NewListCta_scrollContainer__ZYn3D {
    padding-right: 1.5rem;
  }
  .NewListCta_scrollContainer__ZYn3D {
    border-right: none;
  }
}

